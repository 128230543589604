import * as React from "react"
import PropTypes from "prop-types"
import { Fragment } from "react"

import { Link, useI18next } from "gatsby-plugin-react-i18next"
import { Popover, Transition } from "@headlessui/react"
import { StaticImage } from "gatsby-plugin-image"
import {
  AnnotationIcon,
  ChatAlt2Icon,
  InboxIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/outline"
import { ChevronDownIcon } from "@heroicons/react/solid"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
const solutions = [
  {
    name: "Ecoles",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "/schools",
    icon: InboxIcon,
  },
  {
    name: "Universités",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "/university",
    icon: AnnotationIcon,
  },
  {
    name: "Formation professionnelle",
    description: "Your customers' data will be safe and secure.",
    href: "/training",
    icon: ChatAlt2Icon,
  },
]

const Header = ({ siteTitle }) => {
  const { languages, changeLanguage } = useI18next()
  return (
    <header>
      <Popover className="relative bg-gray-900">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">{siteTitle}</span>
              <StaticImage
                src="../images/easytest_logo.png"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Easytest logo"
                width={150}
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-300" : "text-gray-50",
                      "group bg-gray-900 rounded-md inline-flex items-center text-base font-medium hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    )}
                  >
                    <span>Solutions</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-300" : "text-gray-50",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-gray-900 px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-3">
                          {solutions.map(item => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-800"
                            >
                              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-secondary-600 to-primary-600 text-gray-50 sm:h-12 sm:w-12">
                                <item.icon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-50">
                                  {item.name}
                                </p>
                                {/* <p className="mt-1 text-sm text-gray-500">
                              {item.description}
                            </p> */}
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Link
              to="/pricing"
              className="text-base font-medium text-gray-50 hover:text-gray-300"
            >
              Plans
            </Link>
            <Link
              to="/blog"
              className="text-base font-medium text-gray-50 hover:text-gray-300"
            >
              Blog
            </Link>
            <Link
              to="/contact"
              className="text-base font-medium text-gray-50 hover:text-gray-300"
            >
              Contact et Support
            </Link>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {/* <a
          href="#"
          className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
        >
          Sign in
        </a> */}

            <ul className="languages flex space-x-2">
              <li>
                <button
                  onClick={e => {
                    e.preventDefault()
                    changeLanguage("fr")
                  }}
                >
                  🇫🇷
                </button>{" "}
              </li>
              <li>|</li>
              <li>
                <button
                  onClick={e => {
                    e.preventDefault()
                    changeLanguage("en")
                  }}
                >
                  🇬🇧
                </button>{" "}
              </li>
            </ul>

            <a
              href="https://app.easytest.ma"
              target="_blank"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-secondary-600 to-primary-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-secondary-700 hover:to-primary-700"
            >
              Connexion
            </a>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <StaticImage
                      src="../images/easytest_logo.png"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="Easytest logo"
                      imgClassName="h-4 w-auto"
                      width={100}
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid grid-cols-1 gap-7">
                    {solutions.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-secondary-600 to-primary-600 text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">
                          {item.name}
                        </div>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-3 gap-4">
                  <Link
                    to="/pricing"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Pricing
                  </Link>
                  <Link
                    to="/blog"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Blog
                  </Link>
                  <ul className="languages flex justify-center space-x-2">
                    <li>
                      <button
                        onClick={e => {
                          e.preventDefault()
                          changeLanguage("fr")
                        }}
                      >
                        🇫🇷
                      </button>{" "}
                    </li>
                    <li>|</li>
                    <li>
                      <button
                        onClick={e => {
                          e.preventDefault()
                          changeLanguage("en")
                        }}
                      >
                        🇬🇧
                      </button>{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-6">
                  <Link
                    to="/contact"
                    className="w-full flex items-center justify-center bg-gradient-to-r from-secondary-600 to-primary-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-secondary-700 hover:to-primary-700"
                  >
                    Contactez-nous
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
